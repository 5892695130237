import React from "react";
import categories from "../../data/posts";

// import Footer
import Footer from "../Footer";
import SectionHeading from "../SectionHeading";

// import Sidebar
import Sidebar from "../Sidebar";

// import PostThumbnail
import PostThumb from "../PostThumb";

// import scroll to top
import ScrollTop from "../ScrollTop";

const PostPage = ({ match, location }) => {
  const {
    params: { categoryId },
  } = match;

  return (
    <>
      <div className="">
        <div className="container-fluid">
          <div className="columns">
            <div className="column is-2 sidebar-width">
              <Sidebar />
            </div>
            <div className="column">
              <main>
                <SectionHeading heading={categories[categoryId - 1].name} />
                <div className="posts-grid">
                  {categories[categoryId - 1].items
                    .sort((a, b) => a.title.localeCompare(b.title))
                    .map((item, index) => (
                      <div className="grid-item" key={index}>
                        <a
                          className="posts-grid__item"
                          
                          href={item.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="">
                            <PostThumb
                              alt={item.title}
                              image={item.icon}
                            />
                            <div>
                              <h3 className="post-title">{item.title}</h3>
                              <p className="post-description">
                                {item.subtitle}
                              </p>
                            </div>
                          </div>
                        </a>
                        <a
                          href={item.link}
                          className="external-link"
                          target="_blank"
                          rel="noopener noreferrer"
                          title="Visit Website"
                        >
                          <span className="screen-reader">Visit Website</span>
                          <svg
                            data-v-81d25d52=""
                            xmlns=" "
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="external-link__icon"
                          >
                            <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                            <polyline points="15 3 21 3 21 9"></polyline>
                            <line x1="10" y1="14" x2="21" y2="3"></line>
                          </svg>
                        </a>
                      </div>
                    ))}
                </div>
                {/* end grid */}
              </main>
            </div>
          </div>
        </div>
      </div>
      <ScrollTop scrollStepInPx="50" delayInMs="20" />
      <Footer />
    </>
  );
};

export default PostPage;
