import React from "react";

export const Logo = () => {
  return (
    <svg
			className="logo"
			viewBox="0 0 229 229"
    >
      <g>
        <path
          class="sky"
          d="M45.1,129.3c-1.2-5.3-1.8-10.7-1.8-16.5c0-19.4,6.9-36,20.6-49.7c13.7-13.7,30.3-20.6,49.7-20.6
		c0.9,0,1.8,0,2.7,0.1c18.3,0.6,33.9,7.4,47,20.5c13.7,13.7,20.6,30.3,20.6,49.7c0,5.1-0.5,10-1.4,14.6c-1.6-0.2-3.3-0.3-5-0.3
		c-8,0.1-14.8,2.8-20.6,8c-3.2-1.1-6.5-1.6-10.1-1.5c-3.9,0.1-7.6,0.7-11,2c-5.5-5-12.1-7.6-19.6-7.8c-0.4,0-0.9,0-1.3,0
		c-8,0.1-14.8,2.8-20.6,7.9c-3.2-1-6.5-1.5-10.1-1.4c-3.9,0-7.6,0.7-11,1.9c-5.9-5.3-12.9-7.8-21-7.7
		C49.7,128.5,47.4,128.8,45.1,129.3z"
        />
        <path
          class="waves"
          d="M45.1,129.3c2.3-0.5,4.7-0.8,7.1-0.8c8.1-0.1,15.1,2.4,21,7.7c3.4-1.2,7.1-1.8,11-1.9
		c3.6-0.1,6.9,0.4,10.1,1.4c5.8-5.2,12.7-7.8,20.6-7.9c0.4,0,0.9,0,1.3,0c7.5,0.2,14.1,2.8,19.6,7.8c3.4-1.2,7.1-1.9,11-2
		c3.5,0,6.9,0.5,10.1,1.5c5.8-5.2,12.6-7.9,20.6-8c1.7,0,3.4,0.1,5,0.3 M180.7,134.5c-1-0.1-2.1-0.2-3.1-0.2
		c-5.1,0.1-9.5,1.5-13.4,4.2c1.7,1.2,3.4,2.5,5,4.1c0.5,0.4,1,0.9,1.4,1.4c2.1-1.2,4.5-1.8,7.2-1.9 M170.4,144.1
		c1.4,1.5,2.6,3.1,3.7,4.8 M135.8,135.6c-2.4,1-4.7,2.2-6.8,3.7c-1.5,1.1-2.9,2.3-4.3,3.6c-0.5,0.6-1,1.2-1.5,1.7
		c-2.1,2.4-3.7,4.9-4.8,7.7c2.5,0.2,4.9,0.7,7.2,1.5c0.9-1.6,1.9-3.1,3.1-4.6c0.3-0.5,0.7-0.9,1.1-1.3c1.4-1.4,2.9-2.6,4.5-3.6
		c2.1-1.3,4.3-2.3,6.8-3c1.8-0.4,3.8-0.7,5.8-0.7c1.6,0,3.2,0.1,4.7,0.4c2.5,0.4,4.9,1.3,7.1,2.5c1.9,1,3.6,2.4,5.3,4
		c0.3,0.3,0.6,0.6,1,0.9c1.3,1.4,2.4,2.9,3.2,4.4c1.2-0.4,2.5-0.7,3.9-1 M125.7,153.9c2.4,0.9,4.8,2.1,7,3.6
		c0.7-1.4,1.7-2.7,2.8-3.9c0.8-0.8,1.7-1.5,2.6-2.1c2.1-1.4,4.3-2.3,6.8-2.7c0.8-0.1,1.6-0.2,2.4-0.2c0.2,0,0.4,0,0.7,0.1
		c2.5,0,4.9,0.6,7,1.7c1.5,0.8,2.9,1.8,4.2,3c0.9,0.9,1.6,1.8,2.2,2.8c2.1-1.3,4.4-2.3,6.8-3.1 M118.4,152.3
		c-0.7-0.1-1.5-0.1-2.2-0.1c-0.4,0-0.9,0-1.3,0c-0.6,0-1.3,0-1.9,0.1c-2.6,0.2-5.1,0.6-7.5,1.4c-2.3,0.7-4.6,1.7-6.7,3
		c-1.6,1-3.1,2.1-4.5,3.4c2.5,0.9,4.9,2,7.2,3.4c3.9-2.7,8.4-4.1,13.5-4.2c0.1,0,0.2,0,0.2,0c0.4,0,0.7,0,1.1,0.1h0.1
		c4.8,0.2,9.1,1.6,12.8,4.3c2.1-1.5,4.3-2.7,6.8-3.7c-1-0.9-2-1.8-3.1-2.5 M161.4,156.1c-1.6,1-3.1,2.1-4.5,3.4
		c2.3,0.7,4.4,1.7,6.5,2.9 M135.8,160c3.4-1.3,7.1-2,11-2c3.5,0,6.9,0.5,10.1,1.5 M156.9,135.2c2.5,0.8,4.9,1.9,7.2,3.4
		 M129.1,139.3c-3.8-2.7-8-4.2-12.8-4.3c-0.4,0-0.8,0-1.3,0c-5.1,0-9.6,1.4-13.5,4.2c1.7,1.1,3.4,2.5,5,4c0.5,0.5,0.9,1,1.4,1.4
		c2.2-1.2,4.7-1.8,7.5-1.8c0.3,0,0.6,0,1,0c2.5,0.1,4.9,0.7,7,1.9 M73.3,136.2c-2.4,1-4.7,2.3-6.8,3.8c-1.5,1.1-2.9,2.3-4.3,3.7
		c-0.5,0.5-1,1.1-1.5,1.6c-1.7,2.1-3.2,4.2-4.3,6.6c-0.2,0.4-0.4,0.8-0.6,1.2c0.2,0,0.4,0,0.6,0.1c2.3,0.2,4.5,0.7,6.7,1.4
		c0.9-1.6,1.9-3.2,3.1-4.6c0.4-0.4,0.8-0.8,1.2-1.2c1.4-1.5,2.8-2.7,4.4-3.6c2.1-1.3,4.4-2.3,6.8-2.9c1.9-0.4,3.8-0.6,5.9-0.6
		c1.6,0,3.2,0.1,4.7,0.3c2.5,0.4,4.9,1.3,7.1,2.6c1.8,1,3.6,2.3,5.3,4c0.3,0.3,0.6,0.6,0.9,0.9c1.3,1.4,2.4,2.9,3.2,4.5 M63,154.5
		c2.5,0.9,4.9,2,7.1,3.6c0.7-1.4,1.7-2.7,2.8-3.9c0.8-0.8,1.6-1.5,2.5-2.2c2-1.3,4.3-2.2,6.8-2.6c0.8-0.1,1.6-0.2,2.4-0.2
		c0.2,0,0.5,0,0.7,0c2.5,0,4.8,0.6,7,1.7c1.5,0.7,2.9,1.8,4.2,3.1c0.9,0.8,1.7,1.7,2.3,2.7 M70.1,158.1c1.1,0.7,2.1,1.6,3.1,2.5
		c3.4-1.2,7.1-1.9,11-1.9c3.6-0.1,6.9,0.4,10.1,1.4 M113,152.3c-1.3-2.8-3-5.3-5.2-7.7 M101.5,139.2c-2.3-1.4-4.7-2.6-7.2-3.4
		 M116.4,183.1c1-5.3,3.2-9.9,6.9-14.1c-2.1-1.2-4.5-1.8-7-1.9c-0.3,0-0.6,0-1,0c-2.7,0-5.2,0.7-7.5,1.9c3.8,4.2,6.2,8.9,7.3,14.2
		 M71.7,169.3c2.1-1.3,4.4-2.3,6.8-2.9c1.9-0.4,3.8-0.6,5.9-0.6c1.6,0,3.2,0.1,4.7,0.4c2.5,0.4,4.9,1.3,7.1,2.5c1.8,1,3.6,2.4,5.3,4
		c0.3,0.3,0.6,0.6,0.9,0.9c2.6,2.8,4.4,5.9,5.4,9.4 M101.5,163.5c1.7,1.1,3.4,2.5,5,4c0.5,0.5,0.9,1,1.4,1.4 M79.3,174.5
		c0.9-0.3,1.9-0.6,2.9-0.7c0.8-0.1,1.6-0.2,2.4-0.2c0.2,0,0.5,0,0.7,0c2.5,0,4.8,0.6,7,1.7c1.5,0.7,2.9,1.8,4.2,3
		c1,0.9,1.8,1.8,2.4,2.9c0.1,0.2,0.2,0.4,0.4,0.6 M123.9,182.4c0.9-3.2,2.6-6.1,4.9-8.8c0.3-0.5,0.7-0.9,1.1-1.3
		c1.4-1.4,2.9-2.6,4.5-3.6c2.1-1.3,4.3-2.3,6.8-3c1.8-0.4,3.8-0.7,5.8-0.7c1.6,0,3.2,0.2,4.7,0.5c2.2,0.4,4.2,1,6.2,2 M133.5,180.5
		c0.6-0.9,1.2-1.7,2-2.6c0.8-0.8,1.7-1.5,2.6-2.1c2.1-1.4,4.3-2.3,6.8-2.7c0.8-0.1,1.6-0.2,2.4-0.2c0.2,0,0.4,0,0.7,0.1
		c0.7,0,1.3,0.1,2,0.2 M123.3,169c0.5-0.6,1-1.1,1.5-1.7c1.4-1.4,2.8-2.6,4.3-3.7 M66.4,140c-4.1-3-8.8-4.4-14.1-4.3
		c-1.8,0-3.6,0.2-5.3,0.6 M50.1,143.6c0.8-0.1,1.7-0.2,2.6-0.2c2.9,0,5.6,0.6,8,1.9 M66,164.6c0.1-0.1,0.3-0.2,0.4-0.3
		c-0.7-0.5-1.5-1-2.3-1.4 M66.4,164.3c2.1-1.5,4.4-2.7,6.8-3.7"
        />
        <path
          class="border"
          d="M172.7,173.6c-16.3,16.3-36,24.5-59.1,24.5c-23.1,0-42.8-8.2-59.1-24.5c-16.3-16.3-24.5-36-24.5-59.1
		c0-23.1,8.2-42.8,24.5-59.1c16.3-16.3,36-24.5,59.1-24.5c23.1,0,42.8,8.2,59.1,24.5c16.3,16.3,24.5,36,24.5,59.1
		C197.2,137.6,189,157.3,172.7,173.6z"
        />
      </g>
    </svg>
  );
};
