const posts = [
  {
    name: "Inspiration",
    catID: 1,
    icon:
      "https://res.cloudinary.com/dbjveaw0x/image/upload/v1568042960/tools-and-resources%20web/inspiration_jlsmq1.svg",
    items: [
      {
        title: "Landingfolio",
        icon: "https://logo.clearbit.com/landingfolio.com",
        subtitle: "Landing page examples",
        link: "https://www.landingfolio.com/"
      },
      {
        title: "Land-book",
        icon: "https://logo.clearbit.com/land-book.com/",
        subtitle: "The finest hand-picked website inspirations",
        link: "https://land-book.com/"
      },
      {
        title: "Lapa Ninja",
        icon: "https://logo.clearbit.com/lapa.ninja/",
        subtitle: "Landing page examples",
        link: "https://www.lapa.ninja/"
      },
      {
        title: "Pages.xyz",
        icon: "https://logo.clearbit.com/www.pages.xyz",
        subtitle: "Landing page examples",
        link: "https://www.pages.xyz"
      },
      {
        title: "Pixels",
        icon: "https://logo.clearbit.com/klart.io",
        subtitle: "Kick-ass designs and copywriting",
        link: "https://klart.io/pixels"
      },
      {
        title: "httpster",
        icon: "https://logo.clearbit.com/httpster.net/",
        subtitle: "Inspiration resource showcasing totally rocking websites made by people from all over the world",
        link: "https://httpster.net/"
      },
      {
        title: "CSS Design Awards",
        icon: "https://logo.clearbit.com/cssdesignawards.com/",
        subtitle: "Website Awards",
        link: "https://www.cssdesignawards.com/"
      },
      {
        title: "Collect UI",
        icon: "https://logo.clearbit.com/collectui.com/",
        subtitle: "Daily inspiration collected from daily ui archive and beyond. Hand picked, updating daily",
        link: "https://collectui.com/"
			},
      {
        title: "Email Love",
        icon: "https://emaillove.com/wp-content/themes/emaillove/frontend/img/favicon/apple-touch-icon.png",
        subtitle: "Browse Email Inspiration, find Email Templates or discover Great Newsletters",
        link: "https://emaillove.com/"
      },
      {
        title: "SaaS landing page examples",
        icon: "https://logo.clearbit.com/https://saaslandingpage.com/",
        subtitle: "Discover the best landing page examples created by top-class SaaS companies.",
        link: "https://saaslandingpage.com/"
      },
      {
        title: "Pages.ooo",
        icon: "https://logo.clearbit.com/https://www.pages.ooo/",
        subtitle: "A showcase of the Impressive Web and Interactive Design.",
        link: "https://www.pages.ooo/"
      },
      {
        title: "Site inspire",
        icon: "https://logo.clearbit.com/https://www.siteinspire.com/",
        subtitle: "Web design inspiration.",
        link: "https://www.siteinspire.com/"
      },
      {
        title: "Admire The Web",
        icon: "https://logo.clearbit.com/https://www.admiretheweb.com/",
        subtitle: "The very best in web design inspiration.",
        link: "https://www.admiretheweb.com/"
      },
      {
        title: "Commerce Cream",
        icon: "https://logo.clearbit.com/https://commercecream.com/",
        subtitle: "Curating the Best of Shopify.",
        link: "https://commercecream.com/"
      }
			
    ]
  },
  {
    name: "Colors",
    catID: 2,
    icon:
      "https://res.cloudinary.com/dbjveaw0x/image/upload/v1568042437/tools-and-resources%20web/colors_rkjxeg.svg",
    items: [
      {
        title: "Color Hunt",
        icon: "https://colorhunt.co/img/color-hunt-palettes-logo.png",
        subtitle: "Colors pallettes for designers and artists.",
        // id: "1",
        link: "https://colorhunt.co"
      },
      {
        title: "My color space",
        icon: "https://logo.clearbit.com/mycolor.space",
        subtitle: "Never waste Hours on finding the perfect Color Palette again!",
        // id: "2",
        link: "https://mycolor.space"
      },
      {
        title: "Use all five",
        icon: "https://logo.clearbit.com/abc.useallfive.com/",
        subtitle: "Accessible brand colors.",
        // id: "3",
        link: "https://abc.useallfive.com/"
      },
      {
        title: "Khroma",
        icon: "https://logo.clearbit.com/khroma.co/",
        subtitle: "The AI color tool for designers.",
        // id: "4",
        link: "http://khroma.co/"
      },
      {
        title: "Color mind",
        icon: "https://logo.clearbit.com/http://colormind.io/",
        subtitle: "The AI power color palette generator.",
        // id: "5",
        link: "http://colormind.io/"
      },
      {
        title: "Culrs",
        icon: "https://logo.clearbit.com/culrs.com/",
        subtitle: "Colors that work together are curated and fashioned into quality palettes that you can incorporate.",
        // id: "6",
        link: "https://culrs.com/"
      }
      ,
      {
        title: "Coolors",
        icon: "https://logo.clearbit.com/coolors.co/",
        subtitle: "The super fast color schemes generator!",
        // id: "7",
        link: "https://coolors.co"
      },
      {
        title: "Brandcolors",
        icon: "https://logo.clearbit.com/brandcolors.net/",
        subtitle: "The biggest collection of official brand color codes around.",
        // id: "8",
        link: "https://brandcolors.net"
      },
      {
        title: "Colours Cafe",
        icon: "https://logo.clearbit.com/instagram.com/colours.cafe/",
        subtitle: "The biggest collection of official brand color codes around.",
        // id: "9",
        link: "https://instagram.com/colours.cafe/"
      },
      {
        title: "Happy Hues",
        icon: "https://logo.clearbit.com/https://www.happyhues.co/",
        subtitle: "Curated colors in context.",
        // id: "10",
        link: "https://www.happyhues.co/"
      },
      {
        title: "Colorsinspo",
        icon: "https://logo.clearbit.com/https://colorsinspo.com/",
        subtitle: "All in one resource to find everything about colors with extreme ease.",
        // id: "11",
        link: "https://colorsinspo.com/"
      }
    ]
  },
  {
    name: "Icons",
    catID: 3,
    icon:
      "https://res.cloudinary.com/dbjveaw0x/image/upload/v1568042534/tools-and-resources%20web/icons_r8rza3.svg",
    items: [
      {
        title: "Icons8",
        icon: "https://logo.clearbit.com/icons8.com/",
        subtitle: "91,000 collection of free icons",
        link: "https://icons8.com/icons"
      },
      {
        title: "Vivid",
        icon: "https://webkul.github.io/vivid/assets/res/vivid-favicon-57x57.png?1.0.1",
        subtitle: "Open Source SVG Icons Pack JavaScript Library",
        link: "https://webkul.github.io/vivid/"
      },
      {
        title: "Ikonate",
        icon: "https://ikonate.com/favicon.png",
        subtitle: "Fully customisable & accessible vector icons",
        link: "https://www.ikonate.com/"
      },
      {
        title: "Thenounproject",
        icon: "https://logo.clearbit.com/thenounproject.com",
        subtitle: "Icons for everything",
        link: "https://thenounproject.com/"
      },
      {
        title: "Icon store",
        icon: "https://logo.clearbit.com/iconstore.co",
        subtitle: "Free Icons by First-Class Designers",
        link: "https://iconstore.co"
      },
      {
        title: "Simple Icons",
        icon: "https://logo.clearbit.com/simpleicons.org/",
        subtitle: "Free SVG icons for popular brands",
        link: "https://simpleicons.org/"
      },
      // {
      //   title: "Illustrio",
      //   icon: "https://logo.clearbit.com/illustrio.com/",
      //   subtitle: "100% free, 100% customizable icon library",
      //   link: "https://illustrio.com/"
      // },
      {
        title: "Icon SVG",
        icon: "https://iconsvg.xyz/favicon-32.png",
        subtitle: "Quick customizable SVG icons for your projects",
        link: "https://iconsvg.xyz/"
      },
      {
        title: "Remix icon",
        icon: "https://remixicon.com/favicon.ico",
        subtitle: "Simply Delightful Icon System",
        link: "https://remixicon.com/"
      },
      {
        title: "Potlab icons",
        icon: "/images/potlab-logo.png",
        subtitle: "Animated SVG icons for web projects",
        link: "http://www.potlabicons.com/index.html"
      },
      {
        title: "500+ CSS Icons",
        icon: "https://css.gg/fav/apple-icon-60x60.png",
        subtitle: "Minimalistic icon library Designed by code",
        link: "https://css.gg/"
			},
			{
        title: "Lordicon",
        icon: "https://logo.clearbit.com/https://lordicon.com/",
        subtitle: "Interactive, animated web icons for the unforgettable website experience.",
        link: "https://lordicon.com/"
      },
			{
        title: "Icon Scout",
        icon: "https://logo.clearbit.com/https://iconscout.com/",
        subtitle: "High-quality Icons, Illustrations, and Stock photos at one place.",
        link: "https://iconscout.com/"
      },
			{
        title: "Hero Icons",
        icon: "https://heroicons.com/_next/static/media/apple-touch-icon.a91ed695b6b4eded51810cb94c91a093.png",
        subtitle: "Beautiful hand-crafted SVG icons, by the makers of Tailwind CSS.",
        link: "https://heroicons.com/"
      },
			{
        title: "Tabler Icons",
        icon: "https://tablericons.com/favicon.png",
        subtitle: "Fully customizable free SVG icons.",
        link: "https://tablericons.com/"
      },
			{
        title: "Gitmoji",
        icon: "https://gitmoji.carloscuesta.me/static/apple-icon-114x114.png",
        subtitle: "An emoji guide for your commit messages.",
        link: "https://gitmoji.carloscuesta.me/"
      }
    ]
  },
  // {
  //   name: "Components",
  //   catID: 4,
  //   icon:
  //     "https://res.cloudinary.com/dbjveaw0x/image/upload/v1568041634/tools-and-resources%20web/components_sfgexc.svg",
  //   items: [
  //     {
  //       title: "Free front end",
  //       icon: "https://logo.clearbit.com/freefrontend.com",
  //       subtitle: "Free front end",
  //       id: "1",
  //       link: "https://freefrontend.com/"
  //     }
  //   ]
	// },

  {
    name: "Fonts",
    catID: 4,
    icon:
      "https://res.cloudinary.com/dbjveaw0x/image/upload/v1598549015/tools-and-resources%20web/fonts_f2ka1d.svg",
    items: [
      {
        title: "Dev Fonts",
        icon: "https://devfonts.gafi.dev/apple-touch-icon.png",
        subtitle: "Interactive list of the best fonts for developers with options to preview fonts and themes.",
        link: "https://devfonts.gafi.dev/"
			},
      {
        title: "FontSpark",
        icon: "https://fontspark.app/favicon.ico",
        subtitle: "Helping designers discover the perfect font for their next design project.",
        link: "https://fontspark.app/"
      },			
    ]
  },
  {
    name: "Templates",
    catID: 5,
    icon:
      "https://res.cloudinary.com/dbjveaw0x/image/upload/v1568042437/tools-and-resources%20web/templates_r12wog.svg",
    items: [
      {
        title: "Freebie supply",
        icon: "https://logo.clearbit.com/freebiesupply.com",
        subtitle: "The freshest design resources for Adobe XD, Figma, Sketch, Photoshop and Adobe Illustrator",
        link: "https://freebiesupply.com"
      },
      {
        title: "Cruip",
        icon: "https://logo.clearbit.com/cruip.com",
        subtitle: "Landing page templates for startups",
        // id: "2",
        link: "https://cruip.com/"
      },
      {
        title: "Freisbug",
        icon: "https://cdn.freebiesbug.com/wp-content/themes/freebiesbug/assets/images/logo.png",
        subtitle: "Hand-picked resources for web designer and developers, constantly updated",
        // id: "3",
        link: "https://freebiesbug.com"
      },
      {
        title: "HTML5UP",
        icon: "https://logo.clearbit.com/html5up.net/",
        subtitle: "Spiffy HTML5 site templates",
        // id: "4",
        link: "https://html5up.net/"
      },
      {
        title: "One page love",
        icon: "https://logo.clearbit.com/onepagelove.com",
        subtitle: "One Page websites, templates and resources",
        // id: "5",
        link: "https://onepagelove.com/"
      },
      {
        title: "WordPress themes",
        icon: "https://logo.clearbit.com/andersnoren.se/teman/",
        subtitle: "WordPress themes for bloggers, photographers, designers and businesses",
        // id: "6",
        link: "https://andersnoren.se/teman/"
			},
			{
        title: "FREE UI resources",
        icon: "https://logo.clearbit.com/https://uidesigndaily.com/",
        subtitle: "Weekly FREE UI resources.",
        link: "https://uidesigndaily.com/"
      }
    ]
  },
  {
    name: "Patterns",
    catID: 6,
    icon:
      "https://res.cloudinary.com/dbjveaw0x/image/upload/v1568042743/tools-and-resources%20web/patterns_kkbjsg.svg",
    items: [
      {
        title: "Wow patterns",
        icon: "https://logo.clearbit.com/wowpatterns.com/",
        subtitle: "The best free handcrafted premium patterns and wallpapers.",
        link: "https://www.wowpatterns.com/"
      },
      {
        title: "Subtle patterns",
        icon: "https://www.toptal.com/designers/subtlepatterns/wp-content/themes/tweaker7/images/logo_subtle.svg",
        subtitle: "Free textures for your next web project.",
        link: "https://www.toptal.com/designers/subtlepatterns/thumbnail-view/"
      },
      {
        title: "Hero Patterns",
        icon: "https://logo.clearbit.com/heropatterns.com",
        subtitle: "A collection of repeatable SVG background patterns for you to use on your web projects.",
        link: "http://www.heropatterns.com"
      },
      {
        title: "The Pattern Library",
        icon: "https://logo.clearbit.com/thepatternlibrary.com/",
        subtitle: "The Pattern Library.",
        link: "http://thepatternlibrary.com/"
      },
      {
        title: "Cool Backgrounds",
        icon: "https://logo.clearbit.com/coolbackgrounds.io/",
        subtitle: "Cool Backgrounds is a collection of tools to create compelling, colorful images for blogs, social media, and websites.",
        link: "http://coolbackgrounds.io/"
      },
      {
        title: "Patternizer",
        icon: "https://logo.clearbit.com/https://patternizer.com/v60m/",
        subtitle: "Stripe pattern generating tool",
        link: "https://patternizer.com/v60m"
      },
			{
        title: "Beautiful Dingbats",
        icon: "https://logo.clearbit.com/https://beautifuldingbats.com/pattern-generator/",
        subtitle: "Pattern Generator.",
        link: "https://beautifuldingbats.com/pattern-generator/"
			},
			{
				title: "Pattern CSS",
        icon: "https://d33wubrfki0l68.cloudfront.net/78a40466d76ea4f81b0bcb137ab25e6447eed3f6/5568e/assets/images/logo-white.jpg",
        subtitle: "CSS only library to fill your empty background with beautiful patterns.",
        link: "https://bansal.io/pattern-css"
			},
			{
				title: "CSS3 Patterns",
        icon: "https://d33wubrfki0l68.cloudfront.net/78a40466d76ea4f81b0bcb137ab25e6447eed3f6/5568e/assets/images/logo-white.jpg",
        subtitle: "CSS only library to fill your empty background with beautiful patterns.",
        link: "https://leaverou.github.io/css3patterns/"
			}
    ]
  },
  {
    name: "Illustrations",
    catID: 7,
    icon:
      "https://res.cloudinary.com/dbjveaw0x/image/upload/v1568042437/tools-and-resources%20web/illustrations2_qxioz8.svg",
    items: [
      {
        title: "Mixkit",
        icon: "https://logo.clearbit.com/mixkit.co",
        subtitle: "Beautiful art & illustration.",
        // id: "1",
        link: "https://mixkit.co/art/"
      },
      {
        title: "unDraw Illustrations",
        icon: "https://logo.clearbit.com/undraw.co/",
        subtitle: "Take advantage of the on-the-fly color image generation to match your brand identity.",
        // id: "2",
        link: "https://undraw.co/illustrations"
      },
      {
        title: "icons8 Illustrations",
        icon: "https://logo.clearbit.com/icons8.com/",
        subtitle: "Free Vector Illustrations to Class up Your Project.",
        // id: "3",
        link: "https://icons8.com/ouch"
      },
      {
        title: "Humaaans",
        icon: "https://logo.clearbit.com/humaaans.com/",
        subtitle: "Mix-&-match illustrations of people with a design library.",
        // id: "4",
        link: "https://www.humaaans.com/"
      },
      {
        title: "Blush",
        icon: "https://logo.clearbit.com/https://blush.design/",
        subtitle: "Create, mix, and customize illustrations made by artists around the world.",
        // id: "4",
        link: "https://blush.design/	"
			},
			{
        title: "illustrations for 404 pages",
        icon: "https://logo.clearbit.com/https://error404.fun/",
        subtitle: "Make a unique journey through 404 error pages on your website.",
        // id: "4",
        link: "https://error404.fun/	"
      }
    ]
  },
  {
    name: "Generators",
    catID: 8,
    icon:
      "https://res.cloudinary.com/dbjveaw0x/image/upload/v1568048895/tools-and-resources%20web/generator_eiuj8s.svg",
    items: [
      {
        title: "Blob maker",
        icon: "https://logo.clearbit.com/blobmaker.app/",
        subtitle: "SVG Blob maker.",
        link: "https://www.blobmaker.app/"
      },
      {
        title: "Get waves",
        icon: "https://logo.clearbit.com/getwaves.io",
        subtitle: "Create SVG waves for your next project.",
        link: "https://getwaves.io"
      },
      {
        title: "Clip Path Generator",
        icon: "https://www.uplabs.com/logos/uplabs/default--color.svg",
        subtitle: "Clip path generator.",
        link: "https://www.uplabs.com/posts/clip-path-generator"
			},
			{
        title: "CSS Gradient",
        icon: "https://cssgradient.io/images/logo-55c31c59.svg",
        subtitle: "Free tool that lets you create a gradient background for websites.",
        link: "https://cssgradient.io/"
      },
			{
        title: "Neumorphism",
        icon: "https://logo.clearbit.com/https://neumorphism.io/",
        subtitle: "Generate Soft-UI CSS code.",
        link: "https://neumorphism.io/"
      },
			{
        title: "Custom Shape Dividers",
        icon: "https://shapedivider.app/img/logo_500x500.18aca7aa.png",
        subtitle: "Easier for designers and developers to export a beautiful SVG shape divider.",
        link: "https://www.shapedivider.app/"
      },
			{
        title: "Privacy Policy Generator",
        icon: "https://logo.clearbit.com/https://getterms.io/",
        subtitle: "Generate a custom Privacy Policy and Terms of Service statement for your website in minutes.",
        link: "https://getterms.io/"
      },
			{
        title: "Responsive image breakpoints generators",
        icon: "https://logo.clearbit.com/https://responsivebreakpoints.com/",
        subtitle: "Easily generate the optimal responsive image dimensions.",
        link: "https://responsivebreakpoints.com/"
      },
			{
        title: "The Hero Generator",
        icon: "https://hero-generator.netlify.app/favicon.ico",
        subtitle: "Generates the code for a website header.",
        link: "https://hero-generator.netlify.app/"
      },
			{
        title: "Tinkersynth",
        icon: "https://logo.clearbit.com/https://tinkersynth.com/",
        subtitle: "Generates the code for a website header.",
        link: "https://tinkersynth.com/"
      },
			{
        title: "Brumm",
        icon: "https://logo.clearbit.com/https://brumm.af/shadows",
        subtitle: "Make a smooth shadow.",
        link: "https://brumm.af/shadows"
      },
			{
        title: "Sharpen Design",
        icon: "https://logo.clearbit.com/https://sharpen.design/",
        subtitle: "Design challenge generator.",
        link: "https://sharpen.design/"
      }
    ]
  },
  {
    name: "Animations",
    catID: 9,
    icon:
      "https://res.cloudinary.com/dbjveaw0x/image/upload/v1568048946/tools-and-resources%20web/animations_tqc0v4.svg",
    items: [
      {
        title: "MicronJS",
        icon: "https://webkul.github.io/micron/assets/res/micron-logo.svg",
        subtitle: "microInteraction library built with CSS Animations and controlled by JavaScript",
        link: "https://webkul.github.io/micron/"
      },
      {
        title: "LAX",
        icon: "https://logo.clearbit.com/alexfox.dev",
        subtitle: "Awesome scroll effects",
        link: "https://alexfox.dev/laxxx/"
      },
      {
        title: "Animista",
        icon: "https://logo.clearbit.com/animista.net/",
        subtitle: "CSS Animations on demand",
        link: "http://animista.net/"
      },
      {
        title: "SVG Artista",
        icon: "https://logo.clearbit.com/svgartista.net/",
        subtitle: "Super handy SVG drawing animation tool",
        link: "https://svgartista.net/"
      },
      {
        title: "Vivus Instant",
        icon: "https://maxwellito.github.io/vivus-instant/assets/vivus_instant_logo_monochrome.svg",
        subtitle: "Inline SVG animations with css",
        link: "https://maxwellito.github.io/vivus-instant/"
      },
      {
        title: "Animate",
        icon: "https://logo.clearbit.com/https://keyframes.app/",
        subtitle: "Create basic or complex CSS @keyframe animations.",
        link: "https://keyframes.app/animate"
      }
    ]
  },
  // {
  //   name: "Logos",
  //   catID: 10,
  //   icon:
  //     "https://res.cloudinary.com/dbjveaw0x/image/upload/v1568049062/tools-and-resources%20web/logos_l7qkdo.svg",
  //   items: [
  //     {
  //       title: "Looka",
  //       icon: "https://logo.clearbit.com/looka.com/",
  //       subtitle: "Use Looka's AI-powered platform to design a logo",
  //       id: "1",
  //       link: "https://looka.com/"
  //     }
  //   ]
  // },
  {
    name: "Image Tools",
    catID: 11,
    icon:
      "https://res.cloudinary.com/dbjveaw0x/image/upload/v1568292957/tools-and-resources%20web/image_tools_x4ajlm.svg",
    items: [
      {
        title: "Remove bg",
        icon: "https://logo.clearbit.com/remove.bg/",
        subtitle: "Remove Image Background",
        link: "https://www.remove.bg/"
      },
      {
        title: "Screely",
        icon: "https://logo.clearbit.com/screely.com/",
        subtitle: "Instantly turn your screenshot into a beautiful mockup",
        link: "https://www.screely.com/"
      },
      {
        title: "Blendy",
        icon: "https://logo.clearbit.com/blendy.ml/",
        subtitle: "Preview CSS backgrounds blend modes",
        link: "https://blendy.ml/"
      },
      {
        title: "Squoosh",
        icon: "https://squoosh.app/logo.af355.svg",
        subtitle: "Make images smaller using best-in-class codecs, right in the browser",
        link: "https://squoosh.app/"
      },
      {
        title: "Photopea",
        icon: "https://logo.clearbit.com/www.photopea.com/",
        subtitle: "Online photo editor",
        link: "https://www.photopea.com/"
      },
      {
        title: "Doka Photo",
        icon: "https://logo.clearbit.com/https://doka.photo/",
        subtitle: "Edit images without leaving your browser",
        link: "https://doka.photo/"
      }
    ]
  },
  {
    name: "Design Tips",
    catID: 11,
    icon:
      "https://res.cloudinary.com/dbjveaw0x/image/upload/v1570116180/tools-and-resources%20web/sketch_yhmc8t.svg",
    items: [
      {
        title: "Better products",
        icon: "",
        subtitle: "84 cognitive biases you should exploit to design better products",
        link: "https://www.mobilespoon.net/2019/04/collection-cognitive-biases-how-to-use.html"
			},
			{
        title: "UI Improvements",
        icon: "",
        subtitle: "8 tips to quickly improve your UIs",
        link: "https://uxdesign.cc/8-more-tips-to-quickly-improve-your-ui-designs-368fb3bea5ba"
      }
    ]
  }

];

export default posts;
